import { OffersFacade } from '@/_store/offers/offers.facade';
import { BaseFilter, OfferListHeader } from '@/_store/offers/offers.types';
import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map, of, switchMap, take } from 'rxjs';
import { TopSearchDropdownItemMode, TopSearchFilterElType } from '../top-search-dropdown-item.types';
import { GeneralDataFacade } from '@/_store/general/general.facade';
import { Offer } from '@/main/main.component';

@Component({
	selector: 'app-search-filter',
	templateUrl: './search-filter.component.html',
	styleUrl: './search-filter.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFilterComponent {
	closeDropdown = output<boolean>();

	sectionWWW$: Observable<Offer[]> = of([]);
	term$ = new BehaviorSubject<string>('');
	offersBySearchTerm$ = this.term$
		.asObservable()
		.pipe(switchMap((term) => (term.length > 2 ? this.offersFacade.searchOffersBySearchTerm(term) : of(null))));

	readonly TopSearchFilterElType = TopSearchFilterElType;
	readonly TopSearchDropdownItemMode = TopSearchDropdownItemMode;

	readonly selectedLocations$: Observable<string[]>;
	readonly headerValues$: Observable<OfferListHeader[]>;

	private readonly offersFacade = inject(OffersFacade);
	private readonly generalFacade = inject(GeneralDataFacade);
	private readonly router = inject(Router);

	constructor() {
		this.sectionWWW$ = this.generalFacade.sectionWWW$;
		this.headerValues$ = this.offersFacade.headerValues$.pipe(
			map((value) => value.filter((value) => value.key === TopSearchDropdownItemMode.LOCATIONS)),
		);
	}

	chooseProduct(product: BaseFilter): void {
		this.closeDropdown.emit(true);
		this.router.navigate(['oferta', product.id]);
	}

	chooseLocation(location: BaseFilter): void {
		this.offersFacade.addLocationsFilterOption(location.id);
		this.termUpdated('');
	}

	termUpdated(term: string): void {
		this.term$.next(term);
	}

	onClearOption(removedOptionName: string): void {
		this.offersFacade.filterOptions$.pipe(take(1)).subscribe((filterOptions) => {
			const location = filterOptions.locations.find((location) => location.name === removedOptionName);
			this.offersFacade.clearLocationsFilter(location?.id);
		});
	}
}
