import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-main-header',
	templateUrl: './main-header.component.html',
	styleUrls: ['./main-header.component.css'],
	standalone: true,
	imports: [CommonModule],
})
export class MainHeaderComponent {
	@Input() link?: string;
}
