<div
	[ngClass]="{
		'!bg-oskarGrey': item()?.defaultOption,
	}"
	style="width: fit-content"
	class="flex items-center justify-between cursor-default bg-[#ddedff] rounded-[12px] px-2 py-1 lg:px-4 lg:py-1.5 text-mainColor"
>
	<p class="text-size">
		<ng-content></ng-content>
	</p>
	@if (!item()?.defaultOption) {
		<i (click)="clearOption.emit(item().key)" class="fa-solid fa-xmark ml-3 text-lg cursor-pointer leading-3"></i>
	}
</div>
