<div class="flex flex-col px-2 md:px-0 md:flex-row w-full">
	@if (isMobile$ | async) {
		<app-top-search-filter
			*ngFor="let item of filters; let i = index"
			(click)="openMobileModal(item.id)"
			[elementType]="item.isInput ? TopSearchFilterElType.INPUT : TopSearchFilterElType.BTN"
			[filter]="item"
			[style.--radius]="isDropdownOpenArray[i] ? '16px 16px 0 0' : '16px'"
			class="relative w-full md:mx-4 my-1 md:my-0"
		>
		</app-top-search-filter>
	} @else {
		<app-top-search-filter
			*ngFor="let item of filters; let i = index"
			[filter]="item"
			[style.--radius]="isDropdownOpenArray[i] ? '16px 16px 0 0' : '16px'"
			[style.maxWidth.px]="itemMaxWidthPx"
			[appTriggerDropdown]="dropdown"
			class="relative w-full md:mx-4 my-1 md:my-0"
		>
			<app-dropdown
				[style.--radius]="'0 0 12px 12px'"
				[style.--border-width]="'0 1px 1px 1px'"
				[style.--border-color]="'#dcdcdc'"
				[style.--shadow]="'0 0 12px rgb(0 0 0 / 8%)'"
				class="dropdown"
				(dropdownStateChange)="changeOpenState($event, i)"
				(closeDropdownEvent)="fetchOffers(); changeOpenState(false, i)"
				dropdown
				[scrollable]="false"
				#dropdown
			>
				<app-top-search-dropdown-item
					[mode]="item.id"
					(valueChange)="valueChange = $event"
					(triggerClose)="closeDropdown()"
				></app-top-search-dropdown-item>
			</app-dropdown>
		</app-top-search-filter>
	}
</div>
