import { OfferListHeader } from '@/_store/offers/offers.types';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

@Component({
	selector: 'app-filter-tile',
	templateUrl: './filter-tile.component.html',
	styleUrl: './filter-tile.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgIf],
})
export class FilterTileComponent {
	item = input<OfferListHeader>();

	clearOption = output<string>();
}
