import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
	selector: 'app-mobile-wrapper',
	template: `<ng-container *ngIf="isMobile"><ng-content></ng-content></ng-container>`,
	standalone: true,
	imports: [NgIf],
})
export class MobileWrapperComponent {
	isMobile = false;

	constructor(private readonly deviceService: DeviceDetectorService) {
		this.isMobile = this.deviceService.isMobile();
	}
}
