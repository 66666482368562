@switch (elementType()) {
	@case (TopSearchFilterElType.BTN) {
		<button class="filter-btn">
			{{ filter().text }}
			<ng-container *ngIf="selected$ | async as selected">({{ selected }})</ng-container>
		</button>
	}
	@case (TopSearchFilterElType.INPUT) {
		<div class="filter-btn">
			<input
				class="w-full bg-transparent text-center focus-visible:outline-0 focus:outline-0"
				placeholder="{{ filter().text }}"
				type="text"
				[formControl]="term"
			/>
			<span class="absolute top-1.5 left-2.5 text-mainColor material-symbols-outlined"> search </span>
		</div>
	}
}

<ng-content select="[dropdown]"></ng-content>
