import { ChangeDetectionStrategy, Component, ViewContainerRef, input, viewChild } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-mobile-dialog',
	standalone: true,
	imports: [MatDialogModule],
	templateUrl: './mobile-dialog.component.html',
	styleUrl: './mobile-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileDialogComponent {
	viewContainerRef = viewChild('dynamicContent', { read: ViewContainerRef });

	title = input<string>();

	constructor(public dialogRef: MatDialogRef<MobileDialogComponent>) {}
}
