import { ComponentRef, Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MobileDialogComponent } from './mobile-dialog.component';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MobileDialogService {
	constructor(private readonly dialog: MatDialog) {}

	afterClose$ = new Subject<void>();

	openDialogWithComponent<C = unknown, T = unknown>(
		component: Type<C>,
		data: { title?: string } & T,
	): ComponentRef<C> {
		const condition = document.body.clientHeight > 750;
		const margin = condition ? 4 : 0;
		const top = condition ? 60 : 0;
		const dialogRef = this.dialog.open(MobileDialogComponent, {
			width: `calc(100vw - ${2 * margin}px)`,
			maxWidth: `calc(100vw - ${2 * margin}px)`,
			height: `calc(100dvh - ${top > 0 ? top + margin : top}px)`,
			position: {
				top: `${top}px`,
			},
			autoFocus: false,
		});

		dialogRef.componentRef.setInput('title', data.title);

		const componentFactory = dialogRef.componentInstance.viewContainerRef().createComponent(component);
		for (const key in data) {
			// eslint-disable-next-line  @typescript-eslint/no-explicit-any
			componentFactory.setInput(key, (data as any)[key]);
		}

		dialogRef.afterClosed().subscribe(() => {
			this.afterClose$.next();
		});

		return componentFactory;
	}

	close(): void {
		this.dialog.closeAll();
	}
}
