<app-top-search-filter
	(termChanged)="termUpdated($event)"
	[value]="term$ | async"
	[appTriggerDropdown]="dropdownSearch"
	[openOnKeyboard]="true"
	[elementType]="TopSearchFilterElType.INPUT"
	[filter]="{
		id: TopSearchDropdownItemMode.SEARCH,
		text: 'Dokąd chcesz jechać?',
		isInput: true,
	}"
	[style.--radius]="dropdownSearch.isOpen ? '16px 16px 2px 2px' : '16px'"
	class="search-filter"
	><app-dropdown class="dropdown" dropdown #dropdownSearch>
		@if (offersBySearchTerm$ | async; as offersBySearchTerm) {
			@if (offersBySearchTerm.locations.length > 0) {
				<app-dropdown-item style="font-weight: 900" [text]="'Kierunek'"></app-dropdown-item>
			}
			@for (location of offersBySearchTerm.locations; track location.id) {
				<app-dropdown-item (itemClick)="chooseLocation(location)" [text]="location.name"></app-dropdown-item>
			}
			@if (offersBySearchTerm.products.length > 0) {
				<app-dropdown-item style="font-weight: 900" [text]="'Wycieczki'"></app-dropdown-item>
			}
			@for (product of offersBySearchTerm.products; track product.id) {
				<app-dropdown-item (itemClick)="chooseProduct(product)" [text]="product.name"></app-dropdown-item>
			}
			@if (offersBySearchTerm.products.length === 0 && offersBySearchTerm.locations.length === 0) {
				<app-dropdown-item [text]="'Brak wyników'"></app-dropdown-item>
			}
		} @else {
			<app-dropdown-item [text]="'Wpisz przynajmniej 3 znaki'"></app-dropdown-item>
		}
	</app-dropdown>
</app-top-search-filter>

<div class="flex flex-wrap mx-2 mb-2">
	@for (item of headerValues$ | async; track item.key) {
		@for (text of item.value.toString().split(','); track $index) {
			<app-filter-tile class="m-0.5" [item]="item" (clearOption)="onClearOption(text.trim())">
				@if (item.key !== 'adults' && item.key !== 'children') {
					<span>{{ text }}</span>
				} @else {
					{{ item.name }}: <span class="font-semibold">{{ item.value }}</span>
				}
			</app-filter-tile>
		}
	}
</div>

@if (sectionWWW$ | async; as sectionWWW) {
	@if (sectionWWW.length > 0) {
		<app-main-standard
			[title]="sectionWWW[0].name"
			[titleSuffix]="sectionWWW[0].nameSuffix"
			[query]="sectionWWW[0].link"
		></app-main-standard>
	}
}
