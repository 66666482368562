import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopSearchComponent } from '@/new-top-search/top-search.component';
import { ButtonModule } from '@/modules/shared/button/button.module';
import { TopSearchFilterItemsComponent } from '@/new-top-search/top-search-filter-items/top-search-filter-items.component';
import { TopSearchFilterComponent } from './top-search-filter/top-search-filter.component';
import { DropdownModule } from '@/common/dropdown/dropdown.module';
import { MultiChoiceComponent } from './top-search-dropdown-item/multi-choice/multi-choice.component';
import { TopSearchCounterComponent } from '@/new-top-search/top-search-dropdown-item/top-search-counter/top-search-counter.component';
import { TopSearchDatePickerComponent } from '@/new-top-search/top-search-dropdown-item/top-search-date-picker/top-search-date-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CounterComponent } from '@/common/counter/counter.component';
import { TopSearchDropdownItemComponent } from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.component';
import { SearchComponent } from '@/common/search/search.component';
import { MatRadioModule } from '@angular/material/radio';
import { SearchFilterComponent } from './top-search-dropdown-item/search-filter/search-filter.component';
import { MainStandardComponent } from '@/main/main-standard/main-standard.component';
import { MobileWrapperComponent } from '@/common/mobile-wrapper/mobile-wrapper.component';
import { FilterTileComponent } from '@/common/filter-tile/filter-tile.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
	declarations: [
		TopSearchComponent,
		TopSearchFilterItemsComponent,
		TopSearchFilterComponent,
		MultiChoiceComponent,
		TopSearchCounterComponent,
		TopSearchDatePickerComponent,
		TopSearchDropdownItemComponent,
		SearchFilterComponent,
	],
	imports: [
		CommonModule,
		ButtonModule,
		DropdownModule,
		MatCheckboxModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		CounterComponent,
		SearchComponent,
		FormsModule,
		MatRadioModule,
		MainStandardComponent,
		MobileWrapperComponent,
		FilterTileComponent,
		MatFormFieldModule,
		MatInputModule,
	],
	exports: [TopSearchComponent],
})
export class NewTopSearchModule {}
