import { ChangeDetectionStrategy, Component, DestroyRef, inject, Injector, input, OnInit, output } from '@angular/core';
import { OfferDropdownItemMode } from '@/offer/new-offer-configurator/offer-dropdown-item/offer-dropdown-item.component';
import { OffersFacade } from '@/_store/offers/offers.facade';
import { Observable } from 'rxjs';
import { ITopSearchConfig } from '@/new-top-search/top-search.config';
import { TopSearchFilterElType } from '../top-search-dropdown-item/top-search-dropdown-item.types';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-top-search-filter',
	templateUrl: './top-search-filter.component.html',
	styleUrls: ['./top-search-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchFilterComponent implements OnInit {
	filter = input<ITopSearchConfig>();
	elementType = input<TopSearchFilterElType>(TopSearchFilterElType.BTN);
	value = input<string>();

	termChanged = output<string>();

	term = new FormControl('');

	readonly OfferDropdownItemMode = OfferDropdownItemMode;
	readonly TopSearchFilterElType = TopSearchFilterElType;

	selected$: Observable<string | number>;

	private readonly offersFacade = inject(OffersFacade);
	private readonly injector = inject(Injector);
	private readonly destroyRef = inject(DestroyRef);

	ngOnInit(): void {
		this.selected$ = this.offersFacade.getHeaderValue(this.filter()?.id);
		this.term.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
			this.termChanged.emit(value);
		});
		toObservable(this.value, { injector: this.injector })
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.term.setValue(value);
			});
	}
}
